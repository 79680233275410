<template>
    <div class="grid p-jc-center p-ai-center vertical-container">
        <div class="col-12 md:col-6 lg:col-4">
            <form @submit.prevent="login()">
                <Card>
                    <template #title>
                        {{title}}
                    </template>
                    <template #content>
                        <div class="p-fluid">
                            <div class="field">
                                <label>Email</label>
                                <InputText v-model="authForm.email" autofocus
                                    :class="{ 'p-invalid': authForm.errors.has('email') }" />
                                <small class="p-error" v-show="authForm.errors.has('email')">
                                    {{ authForm.errors.get('email') }}
                                </small>
                            </div>
                            <div class="field">
                                <label>Password</label>
                                <Password v-model="authForm.password" 
                                    :class="{ 'p-invalid': authForm.errors.has('password') }" :feedback="false" toggleMask />
                                <small class="p-error" v-show="authForm.errors.has('password')">
                                    {{ authForm.errors.get('password') }}
                                </small>
                            </div>
                            <div class="grid field">
                                <div class="col-8">
                                    <div class="field-checkbox">
                                        <Checkbox v-model="authForm.remember_me" id="remember_me" :binary="true" />
                                        <label for="remember_me">Remember Me</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <Button type="submit" label="MASUK" :disabled="authForm.busy" :icon="(authForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'" iconPos="right" />
                                </div>
                            </div>
                        </div>
                        <p class="mb-1">
                            <router-link to="/">Kembali Ke Homepage</router-link>
                        </p>
                        <p class="mb-1">
                            Belum punya akun? klik <router-link to="/auth/register-member">Daftar</router-link>
                        </p>
                    </template>
                </Card>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Masuk",
            api: '/api/auth',
            authForm: new this.$Form({
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                email: null,
                password: null,
                remember_me: false,
            }),
        }
    },
    methods: {
        login(){
            this.$Progress.start();
            this.authForm.post(this.api + '/login')
            .then((response) => {
                this.$Progress.finish();
                this.authForm.clear();
                this.authForm.reset();
                this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                this.$store.commit('setGuard', response.data.data);
                this.$store.dispatch('getUserCredentials').then(() => {
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        this.$router.push('/dashboard');
                    }
                    
                });
            })
            .catch((error) => {
                this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
    },
    created(){
		this.$e.emit('updateTitle', this.title);
	},
}
</script>